const menulist = [
    { menuename: '概览', path: '/overview', name: "overview",iconurl:require("../../assets/icons/gl.png"),role:["admin","agent","user"]},
    { menuename: '系统看板', path: '/billboards', name: "billboards",iconurl:require("../../assets/icons/kanban.png"),role:["admin"]},    
    { menuename: '设备列表', path: '/devicelist', name: "devicelist",iconurl:require('../../assets/icons/sblb.png'),role:["admin","agent","user"]},   
    { menuename: '结算收益', path: '/earnings', name: "earnings",iconurl:require('../../assets/icons/jssy.png'),role:["admin","agent","user"]},
    { menuename: '我的钱包', path: '/purse', name: "purse",iconurl:require('../../assets/icons/jssy.png'),role:["admin","agent","user"]},  
    { menuename: '用户管理', path: '/rate', name: "rate",iconurl:require('../../assets/icons/rate.png'),role:["admin","agent"]},
    { 
        menuename: '工单管理', 
        path: '/tickets', 
        name: "tickets",
        iconurl:require('../../assets/icons/rate.png'),
        child:[
            {menuename:'提现',path:'/payouts',name:"payouts"},
            {menuename:'上机',path:'/ticket',name:"ticket"},
        ],
        role:["admin"]
    },
    { 
        menuename: '业务管理', 
        path: '/business', 
        name: "business",
        iconurl:require('../../assets/icons/ywgl.png'),
        child:[
            {menuename:'业务列表',path:'/businesslist',name:"businesslist"},
            {menuename:'自计费查询',path:'/inquire',name:"inquire"},
        ],
        role:["admin"]
    },
    // { menuename: '工单中心', path: '/tickets', name: "tickets",iconurl:require('../../assets/icons/gd.png') },
    // { menuename: '用量统计', path: '/statistics', name: "statistics",iconurl:require('../../assets/icons/yltj.png') },
    // { menuename: '监控报告', path: '/report', name: "report",iconurl:require("../../assets/icons/jk.png")},
    // {
    //     menuename:'网络机房',
    //     path:'/networkRoom',
    //     name:'networkRoom',
    //     child:[
    //         {menuename:'机房总览',path:'/overview',name:"overview"},
    //         {menuename:'核心机房',path:'/core',name:"core"},
    //         {menuename:'接入机房',path:'/access',name:"access"},            
    //         {menuename:'汇聚机房',path:'/pooled',name:"pooled"}
    //     ]
    // },
]
export { menulist }
